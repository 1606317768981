import Hr from '@components/atoms/Hr';
import legal from '@lib/constants/legal';
import { openHelpDeskChat } from '@lib/helpers/chat';
import { staticImageFile } from '@lib/helpers/publicFiles';
import GridBox from 'components/atoms/GridBox';
import Logo from 'components/atoms/icons/Logo';
import SocialIcon, { SocialTypes } from 'components/atoms/icons/SocialIcon';
import { Heading4 } from 'components/atoms/Typography';
import BREAKPOINTS from 'lib/constants/breakpoints';
import COLORS from 'lib/constants/colors';
import LINKS from 'lib/constants/links';
import ROUTES from 'lib/constants/routes';
import Link from 'next/link';
import React, { FC } from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import AmpWrap from '@components/atoms/amp/AmpWrap';

const StyledHeart = styled.i`
  &,
  &::after {
    border: 2px solid ${COLORS.pink};
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    width: 10px;
    height: 8px;
    border-bottom: 0;
  }
  & {
    box-sizing: border-box;
    position: relative;
    transform: translate(
        calc(-10px / 2 * var(--ggs, 1)),
        calc(-6px / 2 * var(--ggs, 1))
      )
      rotate(-45deg) scale(var(--ggs, 1));
    display: block;
  }
  &::after,
  &::before {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
  }
  &::after {
    right: -9px;
    transform: rotate(90deg);
    top: 5px;
  }
  &::before {
    width: 11px;
    height: 11px;
    border-left: 2px solid ${COLORS.pink};
    border-bottom: 2px solid ${COLORS.pink};
    left: -2px;
    top: 3px;
  }
`;

const Title = styled(Heading4)`
  color: rgb(183, 183, 183);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 9px;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 1246px;
`;

const StyledFooter = styled.footer`
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  background-color: ${COLORS.black};
  text-size-adjust: none;

  @media screen and (min-width: ${BREAKPOINTS.medium}) {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media screen and (min-width: ${BREAKPOINTS.large}) {
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
`;

const WhiteLink = styled.a`
  :hover {
    text-decoration: underline;
  }

  font-size: 14px;
  font-weight: lighter;
  color: #fff;
  text-decoration: none;
  line-height: 1.43;
`;

const FooterLi = styled.li`
  -webkit-tap-highlight-color: transparent;
  min-height: 40px;
`;

interface Item {
  link: string;
  text: string;
  external?: boolean;
}

const FooterList: FC<{ items: Item[] }> = ({ items }) => (
  <ul>
    {items.map(({ text, external = false, link }) =>
      external ? (
        <FooterLi key={text}>
          <WhiteLink href={link} target="_blank" rel="noopener noreferrer">
            {text}
          </WhiteLink>
        </FooterLi>
      ) : (
        <FooterLi key={text}>
          <Link passHref href={link}>
            <WhiteLink>{text}</WhiteLink>
          </Link>
        </FooterLi>
      ),
    )}
  </ul>
);

const LogoWrapper = styled(GridBox)`
  display: none;

  svg {
    width: 70px;
  }

  @media screen and (min-width: ${BREAKPOINTS.desk}) {
    display: block;
  }
`;

const AppStoreWrapper = styled(GridBox)`
  flex-direction: row;
  justify-content: flex-start;

  a {
    width: 170px;
    height: 80px;
    position: relative;
  }
`;

interface Props {
  topChildren?: React.ReactElement;
}

const Footer: FC<Props> = ({ topChildren }) => (
  <StyledFooter>
    {topChildren}
    <Wrapper>
      <GridBox width={[1, 1, 0.65]}>
        <LogoWrapper width={[0.2]}>
          <Link passHref href={ROUTES.root}>
            <a>
              <Logo color="#fff" />
            </a>
          </Link>
        </LogoWrapper>

        <GridBox
          justifyContent="flex-start"
          flexDirection="column"
          width={[1, 0.5, 0.25]}
        >
          <GridBox>
            <Title>Ferramentas</Title>
          </GridBox>
          <FooterList
            items={[
              { link: ROUTES.searchDebts, text: 'Consulta de Débitos' },
              { link: ROUTES.stolenBikes, text: 'Sua moto é visada?' },
              {
                link: ROUTES.stats.type({ type: 'motorcycle' }),
                text: 'Ficha técnica de motos',
              },
              {
                link: ROUTES.stats.type({ type: 'car' }),
                text: 'Ficha técnica de Carros',
              },

              { link: ROUTES.compare, text: 'Comparativo Motos' },
              {
                link: ROUTES.carriers.comparative(),
                text: 'Comparativo Seguradoras',
              },
              { link: ROUTES.clubs, text: 'Moto Clubes' },
            ]}
          />
        </GridBox>
        <GridBox
          justifyContent="flex-start"
          flexDirection="column"
          width={[1, 0.5, 0.25]}
        >
          <GridBox>
            <Title>Saiba mais</Title>
          </GridBox>
          <FooterList
            items={[
              { link: ROUTES.blog, text: 'Blog' },
              {
                link: ROUTES.financiamento.home,
                text: 'Financiamento de Veículos',
              },
              { link: ROUTES.insuropedia, text: 'Glossário de Seguros' },
              { link: ROUTES.ipva, text: 'Consulte IPVA' },
              { link: ROUTES.fipe.home, text: 'Tabela FIPE' },
            ]}
          />
        </GridBox>
        <GridBox
          justifyContent="flex-start"
          flexDirection="column"
          width={[1, 0.5, 0.25]}
        >
          <GridBox>
            <Title>Empresa</Title>
          </GridBox>

          <FooterList
            items={[
              {
                link: ROUTES.insurancePage.home('seguro-moto'),
                text: 'Seguro para Motos',
              },
              {
                link: ROUTES.insurancePage.home('seguro-carro'),
                text: 'Seguro para Carros',
              },
              {
                link: ROUTES.insurancePage.home('seguro-caminhao'),
                text: 'Seguro para Caminhões',
              },
              {
                link: ROUTES.insurancePage.carriers('seguro-carro'),
                text: 'Seguradoras de Carro',
              },
              {
                link: ROUTES.insurancePage.carriers('seguro-moto'),
                text: 'Seguradoras de Moto',
              },
              { link: ROUTES.privacy, text: 'Política de Privacidade' },
              { link: ROUTES.terms, text: 'Termos de uso' },
            ]}
          />
        </GridBox>
      </GridBox>

      <GridBox
        justifyContent="flex-start"
        flexDirection="column"
        width={[1, 1, 0.35]}
        mt={[16, 0, 0]}
      >
        <GridBox mt={[0]}>
          <Title>Contato</Title>
          <GridBox justifyContent="flex-start">
            <WhiteLink
              role="button"
              tabIndex={-1}
              onClick={openHelpDeskChat}
              onKeyDown={openHelpDeskChat}
            >
              Chat
            </WhiteLink>
            <WhiteLink href="mailto:fala@luna.ac" style={{ marginLeft: 16 }}>
              Email
            </WhiteLink>
          </GridBox>
        </GridBox>
        <GridBox mt={[16]}>
          <Title>Redes Sociais</Title>
          <GridBox>
            {[
              { href: LINKS.instagram, type: SocialTypes.instagram },
              { href: LINKS.facebook, type: SocialTypes.facebook },
              { href: LINKS.twitter, type: SocialTypes.twitter },
              { href: LINKS.linkedin, type: SocialTypes.linkedin },
            ].map(({ href, type }) => (
              <a
                href={href}
                target="_blank"
                rel="noreferrer"
                aria-label={type}
                key={type}
                style={{ marginRight: 8 }}
              >
                <SocialIcon type={type} size={30} color="#fff" />
              </a>
            ))}
          </GridBox>
          <AmpWrap
            nonAmp={
              <GridBox mt={[16]}>
                <Title>Baixe o nosso App</Title>
                <AppStoreWrapper>
                  <a href={LINKS.appStore} target="_blank" rel="noreferrer">
                    <Image
                      src={staticImageFile('baixarnaappstore.png')}
                      alt="Baixar na app store"
                      layout="fill"
                      objectFit="contain"
                    />
                  </a>

                  <a href={LINKS.googlePlay} target="_blank" rel="noreferrer">
                    <Image
                      src={staticImageFile('disponvelnogoogleplay.png')}
                      alt="disponivel na google play"
                      layout="fill"
                      objectFit="contain"
                    />
                  </a>
                </AppStoreWrapper>
              </GridBox>
            }
          />
        </GridBox>
      </GridBox>
      <GridBox mt={[40, 60, 40]}>
        <p style={{ color: '#fff', fontSize: 12 }}>{legal.brokerDisclaimer}</p>
      </GridBox>
    </Wrapper>
    <Hr bgColor="#fff" style={{ width: '100%' }} />
    <div
      style={{
        color: '#fff',
        fontSize: 12,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <p>Feito com</p>
      <StyledHeart style={{ margin: '0 8px' }} />
      <p>em São Paulo.</p>
    </div>
  </StyledFooter>
);

export default Footer;
