import HamburguerIcon from '@components/atoms/icons/HamburguerIcon';
import Logo from '@components/atoms/icons/Logo';
import AMPSection from '@components/molecules/AMPSection';
import COLORS from '@lib/constants/colors';
import ROUTES from '@lib/constants/routes';
import styled from 'styled-components';
import { navBarlinks } from './NavBar';

const InnerHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  position: relative;
`;

const LogoWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  left: 50%;
  top: 0px;
  transform: translateX(-50%);
`;

const Wrapper = styled.div`
  position: static;
  background: rgba(255, 255, 255, 255);
  border-bottom: 1px solid;
  border-color: rgba(0, 0, 0, 0.12);
  padding: 0 0 0 0;
  margin: 0 0 0 0;
`;

const StyledLogo = styled(Logo)`
  height: 24px;
`;

const Nav = styled.nav`
  padding: 32px 0;


  li {
 padding: 12px;
    a {
    }
  }
`;

const Button = styled.button<{on:string}>`
  font-size: 16px;
  align-self: center;
  border: none;
  padding: 0px;
  color: ${COLORS.black};
  background: transparent;
  font-family: 'Poppins', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AMPNavBar = ({ logoUrl = ROUTES.root }: { logoUrl?: string }) => (
  <>
    <amp-sidebar id="sidebar1" layout="nodisplay" side="left">
      <Nav toolbar-target="target-element">
        <ul>
          {[
            { href: ROUTES.root, text: 'Início' },
            { href: ROUTES.startForm(), text: 'Ver Preço Seguro Auto' },
            ...navBarlinks,
            { href: ROUTES.insuropedia, text: 'Dicionário de Seguros' },
          ].map(({ href, text }) => (
            <li key={href}>
              <a href={href}>{text}</a>
            </li>
          ))}
        </ul>
      </Nav>
    </amp-sidebar>
    <Wrapper>
      <AMPSection>
        <InnerHeader>
          <Button on="tap:sidebar1.toggle">
            <HamburguerIcon color={COLORS.black} />
          </Button>

          <LogoWrapper>
            <a href={logoUrl} target="_top">
              <StyledLogo color={COLORS.black} />
            </a>
          </LogoWrapper>
        </InnerHeader>
      </AMPSection>
    </Wrapper>
  </>
);

export default AMPNavBar;
