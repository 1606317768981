import { QuoteOffersQuery } from '@graphql/generated';
import { useFeature } from '@growthbook/growthbook-react';
import localStorageHelper from '@lib/helpers/localStorage';
import { reportError } from '@lib/services/sentry';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export function useGetQuoteFromLocalStorage() {
  const [quote, setquote] = useState<QuoteOffersQuery['quote'] | null>(null);

  useEffect(() => {
    setquote(localStorageHelper.getQuote());
  }, []);

  return quote;
}

type SetValue<T> = Dispatch<SetStateAction<T>>;

export function useLocalStorage<T>(
  key: string,
  initialValue: T,
): [T, SetValue<T>] {
  // Get from local storage then
  // parse stored json or return initialValue
  const readValue = (): T => {
    // Prevent build error "window is undefined" but keep keep working
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      reportError(error);
      return initialValue;
    }
  };

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(readValue);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue: SetValue<T> = (value) => {
    // Prevent build error "window is undefined" but keeps working
    if (typeof window === 'undefined') {
      reportError(
        `Tried setting localStorage key “${key}” even though environment is not a client`,
      );
    }

    try {
      // Allow value to be a function so we have the same API as useState
      const newValue = value instanceof Function ? value(storedValue) : value;

      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(newValue));

      // Save state
      setStoredValue(newValue);

      // We dispatch a custom event so every useLocalStorage hook are notified
      window.dispatchEvent(new Event('local-storage'));
    } catch (error) {
      reportError(error);
    }
  };

  useEffect(() => {
    setStoredValue(readValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      setStoredValue(readValue());
    };

    // this only works for other documents, not the current one
    window.addEventListener('storage', handleStorageChange);

    // this is a custom event, triggered in writeValueToLocalStorage
    window.addEventListener('local-storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('local-storage', handleStorageChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [storedValue, setValue];
}

export type PriceModel = 'installments' | 'annual';

export function usePriceModel() {
  const localStorageKey = 'price-model';
  const hook = useLocalStorage<PriceModel>(localStorageKey, 'annual');
  const feature = useFeature<boolean>('price-model');

  useEffect(() => {
    if (typeof window === 'undefined') return;

    if (
      feature.source === 'defaultValue' ||
      feature.source === 'unknownFeature'
    )
      return;

    const savedValue = window.localStorage.getItem(localStorageKey);

    if (savedValue) return;

    if (feature.value) {
      hook[1]('installments');
    } else {
      hook[1]('annual');
    }
  }, [feature, hook]);

  return hook;
}
