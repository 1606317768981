import COLORS from '@lib/constants/colors';
import CloseButton from 'components/atoms/buttons/CloseButton';
import Link from 'next/link';
import { FC } from 'react';
import styled from 'styled-components';

const Overlay = styled.div<{ showMenu: boolean }>`
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  opacity: ${({ showMenu }) => (showMenu ? 1 : 0)};
  transition: opacity 0.3s ease 0s;
  top: 0;
  left: 0;
  bottom: 0;

  ${({ showMenu }) => !showMenu && 'transform: translate3d(100%, 0px, 0px);'}
`;

const Wrapper = styled.div<{ showMenu: boolean }>`
  position: fixed;
  right: inherit;
  z-index: 1100;
  width: 280px;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  transition: all 0.3s ease 0s;

  ${({ showMenu }) => !showMenu && 'transform: translate3d(-100%, 0px, 0px);'}
`;

const Container = styled.div`
  background: ${COLORS.black};
  padding: 30px;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;

  button {
    position: absolute;
    right: 0;
    top: 0;
  }
`;

const Nav = styled.nav`
  display: flex;
  flex-flow: column;
  padding: 0px;
  height: 100%;

  a {
    color: #fff;
    text-decoration: none;
    font-weight: lighter;

    :not(:first-child) {
      margin-top: 24px;
    }
  }
`;

const MobileMenu: FC<{
  links: { text: string; href: string }[];
  closeMenu: () => void;
  showMenu: boolean;
}> = ({ links, closeMenu, showMenu }) => (
  <>
    <Overlay
      onClick={closeMenu}
      onKeyPress={closeMenu}
      role="button"
      tabIndex={-1}
      showMenu={showMenu}
    />
    <Wrapper showMenu={showMenu}>
      <Container>
        <CloseButton onClick={closeMenu} color="#fff" />
        <Nav>
          {links.map(({ href, text }, index) => (
            <Link href={href} key={text}>
              <a
                onClick={closeMenu}
                onKeyPress={closeMenu}
                role="menuitem"
                tabIndex={index}
              >
                {text}
              </a>
            </Link>
          ))}
        </Nav>
      </Container>
    </Wrapper>
  </>
);

export default MobileMenu;
