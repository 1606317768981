import styled from 'styled-components';
import COLORS from 'lib/constants/colors';
import BREAKPOINTS from 'lib/constants/breakpoints';

const Section = styled.section<{ hasBorder?: boolean; pastel?: boolean }>`
  @media (min-width: 1128px) {
    padding-right: 80px;
    padding-left: 80px;
  }

  @media (min-width: 744px) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media screen and (max-width: ${BREAKPOINTS.medium}) {
    padding: 32px;
  }

  background-color: ${(props) => (props.pastel ? COLORS.pastel : '#fff')};

  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
  padding: 64px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
`;

export default Section;
