import React from 'react';

const PhoneCallIcon = () => (
  <svg width="15" height="15.98" viewBox="0 0 15 15.98">
    <path
      d="M10.3.67a6.53,6.53,0,0,1,3.91,3.1,6.26,6.26,0,0,1,.52,4.89l-1.25-.36a5,5,0,0,0-.41-3.92A5.23,5.23,0,0,0,9.92,1.89ZM9.65,2.79a4.28,4.28,0,0,1,2.56,2A4.1,4.1,0,0,1,12.55,8l-1.22-.36a2.89,2.89,0,0,0-.24-2.25A3,3,0,0,0,9.29,4Zm-1.23,4,2,.58a2,2,0,0,0-.16-1.54A2.06,2.06,0,0,0,9,4.88Zm1,8.93C4.45,18-3.35,3.19,1.56.69L3,0,5.38,4.54,4,5.22c-1.49.78,1.62,6.71,3.14,6l1.41-.67,2.4,4.52Z"
      fill="#fff"
    />
  </svg>
);

export default PhoneCallIcon;
