import { useEffect, useState } from 'react';

export function setIsAppWebviewSession(value = true) {
  return sessionStorage.setItem('isAppWebView', `${value}`);
}

export default function useIsAppWebView() {
  const [isAppWebView, setisAppWebView] = useState(false);

  useEffect(() => {
    const check = sessionStorage.getItem('isAppWebView') === 'true';

    setisAppWebView(check);
  }, [setisAppWebView]);

  return isAppWebView;
}
