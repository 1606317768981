import { QuoteOffersQuery } from '@graphql/generated';

const REFERRAL = 'referral';

const setReferral = (id: string) => localStorage.setItem(REFERRAL, id);

const getReferral = () => localStorage?.getItem(REFERRAL);

const localStorageHelper = {
  setReferral,
  getReferral,
  setQuote: (quote: QuoteOffersQuery['quote']) =>
    localStorage.setItem('quote', JSON.stringify(quote)),
  getQuote: () => {
    const data = localStorage.getItem('quote');
    if (!data) return null;

    return JSON.parse(data) as QuoteOffersQuery['quote'];
  },
  removeQuote: () => localStorage.removeItem('quote'),
};

export default localStorageHelper;
