import GridBox from '@components/atoms/GridBox';
import HideCrisp from '@components/atoms/HideCrisp';
import SquareLoading from '@components/atoms/SquareLoading';
import AMPNavBar from '@components/organisms/AmpNavBar';
import SEO from '@components/organisms/seo/SEO';
import COLORS from '@lib/constants/colors';
import { useUser } from '@lib/hooks/auth';
import useIsAppWebView from '@lib/hooks/useIsAppWebView';
import Footer from 'components/organisms/Footer';
import NavBar from 'components/organisms/NavBar';
import ROUTES from 'lib/constants/routes';
import { useAmp } from 'next/amp';
import Router, { useRouter } from 'next/router';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  min-height: 500px;
  align-items: center;
`;

const Loading = () => (
  <LoadingWrapper>
    <SquareLoading />
  </LoadingWrapper>
);

interface Props {
  loading?: boolean;
  title?: string;
  metaDescription?: string;
  showFooter?: boolean;
  showNavBar?: boolean;
  showNavbarLinks?: boolean;
  forceAuth?: boolean;
  shareImg?: string;
  className?: string;
  transparentNavbar?: boolean;
  preview?: boolean;
  customAmpPage?: boolean;
  transparentNavbarFontColor?: string;
}

const Wrapper = styled.main``;

const PreviewBox = styled.div`
  position: fixed;
  width: 100%;
  background-color: rgb(0, 0, 0);
  bottom: 0px;
  left: 0px;
  padding: 12px;
  margin: 0px;
  z-index: 10001;
  text-align: center;
  color: #fff;

  a {
    cursor: pointer;
    text-decoration: underline;
    color: rgb(255, 0, 0);
  }
`;

const PageContainer: FC<Props> = ({
  children,
  loading = false,
  title,
  metaDescription,
  showFooter = true,
  showNavBar = true,
  showNavbarLinks = true,
  forceAuth = false,
  shareImg,
  className,
  transparentNavbar,
  preview,
  customAmpPage = false,
  transparentNavbarFontColor,
}) => {
  const auth = useUser();
  const isAmp = useAmp();
  const router = useRouter();
  const isAppWebView = useIsAppWebView();

  useEffect(() => {
    if (forceAuth && !auth.user && !auth.loading) {
      Router.push({
        pathname: ROUTES.signIn,
        query: { redirect: router.asPath },
      });
    }
  }, [auth, forceAuth, router]);

  const getChildren = () => {
    if (forceAuth && !auth.user && !auth.loading) return null;

    if (loading || router.isFallback  ||(forceAuth && auth.loading))
      return isAmp ? (
        <GridBox justifyContent="center" mt={[64]}>
          Carregando...
        </GridBox>
      ) : (
        <Loading />
      );

    return children;
  };

  if (forceAuth && !auth.user && !auth.loading) return null;

  return (
    <>
      {isAppWebView && <HideCrisp />}
      {isAmp && showNavBar && <AMPNavBar />}
      <Wrapper className={className}>
        <SEO
          title={title}
          description={metaDescription}
          socialImgUrl={shareImg}
          customAmp={customAmpPage}
        />
        {showNavBar && !isAmp && (
          <NavBar
            showNavbarLinks={showNavbarLinks}
            transparent={transparentNavbar}
            transparentNavbarFontColor={transparentNavbarFontColor}
          />
        )}
        {getChildren()}
        {showFooter && !loading && <Footer />}
        {preview && (
          <PreviewBox>
            <p>
              You are in a preview mode.{' '}
              <a href="/api/exit-preview">Click here to exit</a>
            </p>
          </PreviewBox>
        )}
      </Wrapper>
    </>
  );
};

export const PageContainerPastelVersion = styled(PageContainer)`
  background-color: ${COLORS.pastel};
  min-height: 100vh;

  nav:first-child {
    background-color: ${COLORS.pastel};
    border-color: transparent;
    position: relative;
  }

  section {
    max-width: 1000px;
  }
`;

export default PageContainer;
