/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import OutlineButton from '@components/atoms/buttons/OutlineButton';
import HamburguerIcon from '@components/atoms/icons/HamburguerIcon';
import PhoneCallIcon from '@components/atoms/icons/PhoneCallIcon';
import { useUser } from '@lib/hooks/auth';
import { useGetQuoteFromLocalStorage } from '@lib/hooks/localStorage';
import { useCallModal } from '@lib/hooks/modal';
import useIsAppWebView from '@lib/hooks/useIsAppWebView';
import GhostButton from 'components/atoms/buttons/GhostButton';
import PrimaryButton from 'components/atoms/buttons/PrimaryButton';
import Logo from 'components/atoms/icons/Logo';
import MobileMenu from 'components/molecules/MobileMenu';
import BREAKPOINTS from 'lib/constants/breakpoints';
import COLORS from 'lib/constants/colors';
import ROUTES from 'lib/constants/routes';
import { trackGetQuoteClick } from 'lib/services/analytics';
import Link from 'next/link';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.nav<{
  showBorder?: boolean;
  transparent: boolean;
  fixed: boolean;
  transparentNavbarFontColor: string;
}>`
  position: ${(props) => (props.fixed ? 'fixed' : 'absolute')};
  top: 0;
  right: 0;
  left: 0;
  background: ${({ transparent, fixed }) =>
    transparent && !fixed ? 'transparent' : '#fff'};
  z-index: 99;
  transition: all 0.3s ease-in;
  color: ${({ transparent, fixed, transparentNavbarFontColor }) =>
    transparent && !fixed ? transparentNavbarFontColor : COLORS.black};
  font-family: 'Poppins', sans-serif;

  border-bottom: ${(props) => props.fixed && '1px solid rgb(237, 242, 249)'};

  @media screen and (max-width: ${BREAKPOINTS.medium}) {
    position: ${(props) => (props.fixed ? 'fixed' : 'absolute')};
  }
`;

const InnerDesktop = styled.div`
  max-width: 1600px;
  padding-right: 80px;
  padding-left: 80px;
  display: flex;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;

  @media screen and (max-width: ${BREAKPOINTS.medium}) {
    display: none;
  }
`;

const InnerMobile = styled.div`
  display: none;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 16px;

  @media screen and (max-width: ${BREAKPOINTS.medium}) {
    display: flex;
  }
`;

const StyledLogo = styled(Logo)`
  height: 24px;
`;

export const navBarlinks = [
  { href: ROUTES.insurancePage.home('seguro-carro'), text: 'Seguro Carro' },
  { href: ROUTES.insurancePage.home('seguro-moto'), text: 'Seguro Moto' },
  { href: ROUTES.blog, text: 'Blog' },
];

const NavBarLink: FC<{ href: string; text: string }> = ({ href, text }) => (
  <Link passHref href={href}>
    <a>{text}</a>
  </Link>
);

const NavBarLinksWrapper = styled.div`
  display: flex;
  align-items: center;

  a {
    margin-left: 32px;

    :hover {
      text-decoration: underline;
    }
  }
`;

const LinkWrapper = styled.a`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  left: 50%;
  top: 0px;
  transform: translateX(-50%);
`;

const NavBar: FC<{
  showNavbarLinks?: boolean;
  transparent?: boolean;
  hideQuoteButton?: boolean;
  transparentNavbarFontColor?: string;
}> = ({
  transparent = false,
  hideQuoteButton = false.valueOf,
  transparentNavbarFontColor = '#fff',
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [fixed, setFixed] = useState(false);
  const [scrolled100vh, setscrolled100vh] = useState(false);
  const isAppWebView = useIsAppWebView();

  const { user } = useUser();

  const mobileLinks = [{ text: 'Início', href: ROUTES.root }, ...navBarlinks];

  user
    ? mobileLinks.push({ text: 'Minha Conta', href: ROUTES.account })
    : mobileLinks.push({ text: 'Entrar', href: ROUTES.signIn });

  const listener = () => {
    const { y } = document.body.getBoundingClientRect();

    setscrolled100vh(window.innerHeight * -1 > y);

    setFixed(y < -130);
  };

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    window.addEventListener('scroll', listener);
    // cleanup function occurs on unmount
    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener('scroll', listener);
  }, []);

  const quote = useGetQuoteFromLocalStorage();

  const [showModal] = useCallModal();

  if (isAppWebView) return null;

  return (
    <Wrapper
      transparentNavbarFontColor={transparentNavbarFontColor}
      fixed={fixed || !transparent}
      transparent={transparent}
    >
      <InnerMobile>
        <GhostButton onClick={() => setShowMenu(true)}>
          <HamburguerIcon
            color={
              transparent && !fixed ? transparentNavbarFontColor : COLORS.black
            }
          />
        </GhostButton>
        <Link passHref href={ROUTES.root}>
          <LinkWrapper>
            <StyledLogo
              color={
                transparent && !fixed
                  ? transparentNavbarFontColor
                  : COLORS.black
              }
            />
          </LinkWrapper>
        </Link>
        {scrolled100vh && !hideQuoteButton ? (
          <Link
            passHref
            href={quote ? ROUTES.offers(quote.id) : ROUTES.startForm()}
          >
            <a>
              <OutlineButton pink>Preços</OutlineButton>
            </a>
          </Link>
        ) : (
          <PrimaryButton onClick={showModal}>
            <PhoneCallIcon />
          </PrimaryButton>
        )}
        <MobileMenu
          showMenu={showMenu}
          closeMenu={() => setShowMenu(false)}
          links={mobileLinks}
        />
      </InnerMobile>
      <InnerDesktop>
        <Link passHref href={ROUTES.root}>
          <a>
            <StyledLogo
              color={
                transparent && !fixed
                  ? transparentNavbarFontColor
                  : COLORS.black
              }
            />
          </a>
        </Link>

        <NavBarLinksWrapper>
          {navBarlinks
            .filter(({ text }) => (user ? text !== 'Entrar' : true))
            .map((item) => (
              <NavBarLink key={item.text} {...item} />
            ))}

          <NavBarLink
            href={user ? ROUTES.account : ROUTES.signIn}
            text={user ? 'Minha Conta' : 'Entrar'}
          />

          {!user && !hideQuoteButton && (
            <Link
              passHref
              href={quote ? ROUTES.offers(quote.id) : ROUTES.startForm()}
            >
              <a>
                <PrimaryButton
                  onClick={() => trackGetQuoteClick('navbar button')}
                >
                  <span>{quote ? 'Voltar Para Cotação' : 'Ver Preços'}</span>
                </PrimaryButton>
              </a>
            </Link>
          )}
        </NavBarLinksWrapper>
      </InnerDesktop>
    </Wrapper>
  );
};

export default NavBar;
