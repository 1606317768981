const HamburguerIcon = ({ color }: {color: string}) => (
  <svg
    width="29"
    height="19"
    viewBox="0 0 29 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.594727 0.27832H28.4053V2.26074H0.594727V0.27832ZM7.56152 10.2188L0.311523 8.20801L0.90625 6.22559L7.56152 8.20801L14.5 6.22559L21.4385 8.20801L28.0938 6.22559L28.6885 8.20801L21.4385 10.2188L14.5 8.20801L7.56152 10.2188ZM3.37012 18.3467L0 15.9678L1.18945 14.3818L3.37012 15.9678L6.14551 13.9854L8.94922 15.9678L11.7246 13.9854L14.5 15.9678L17.2754 13.9854L20.0508 15.9678L22.8545 13.9854L25.6299 15.9678L27.8105 14.3818L29 15.9678L25.6299 18.3467L22.8545 16.3643L20.0508 18.3467L17.2754 16.3643L14.5 18.3467L11.7246 16.3643L8.94922 18.3467L6.14551 16.3643L3.37012 18.3467Z"
      fill={color}
    />
  </svg>
);

export default HamburguerIcon;
