import styled from 'styled-components';

interface Props {
  bgColor?: string;
  margin?: number;
}

const Hr = styled.hr<Props>`
  margin: ${({ margin = 32 }) => `${margin}px 0`};
  background: ${(props) => props.bgColor || '#ebebeb'};
`;

export default Hr;
